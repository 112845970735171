import { bindActionCreators } from 'redux';
import { Clickable, Strong } from '@ahmdigital/ui';
import { connect } from 'react-redux';
import { defaultsAll, find, get, has, head, isNil, parseInt, upperFirst } from 'lodash/fp';
import React, { Fragment } from 'react';

import { DisclaimerWrapper, StyledButton, StyledDiv, StyledP } from './styles';
import { DRAWER_IDS } from '../../ahm-constants/ui';
import { RootState } from '../../reducers';
import constants from '../../ahm-constants';
import drawerActionCreators from '../../actions/drawer';
import hasHospitalComponent from '../../utils/has-hospital-component';
import PriceService from '../../models/price';
import selectCalculatedYouthDiscountPercentage, {
  YouthDiscountPercentageType,
} from '../../selectors/select-calculated-youth-discount-percentage';
import selectCartHospitalOrPackageProduct from '../../selectors/select-cart-hospital-or-package-product';
import selectCustomer from '../../selectors/select-customer';
import selectCustomerHumanReadable from '../../selectors/select-customer-human-readable';
import selectIsExcessToggleApplicable from '../../selectors/select-is-excess-toggle-applicable';
import selectPrices from '../../selectors/select-prices';
import selectRebatePercentage from '../../selectors/select-rebate-percentage';

const getAgeSentence =
  // @ts-expect-error - Automatic, Please fix when editing this file


    (DataWrapper) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    (ages, usePossessiveDeterminer = false) =>
      (
        <>
          based on
          {has(constants.RELATIONSHIP.CLIENT, ages) && (
            <DataWrapper>
              {' '}
              {usePossessiveDeterminer ? 'your' : 'an'} age of {ages[constants.RELATIONSHIP.CLIENT]}
            </DataWrapper>
          )}
          {has(constants.RELATIONSHIP.CLIENT, ages) && has(constants.RELATIONSHIP.SPOUSE, ages) && <> and</>}
          {has(constants.RELATIONSHIP.SPOUSE, ages) && (
            <DataWrapper>
              {' '}
              {usePossessiveDeterminer ? 'your' : 'a'} partner’s age of {ages[constants.RELATIONSHIP.SPOUSE]}
            </DataWrapper>
          )}
        </>
      );

// @ts-expect-error - Automatic, Please fix when editing this file
const getYouthDiscountSentence = (DataWrapper) => (customer) => {
  const { ages, calculatedYouthDiscountPercentage, whicsYouthDiscountPercentage } = customer;
  const youthDiscountPercentage = whicsYouthDiscountPercentage || calculatedYouthDiscountPercentage;

  if (youthDiscountPercentage > 0) {
    return (
      <>
        , a Youth Discount of <DataWrapper>{youthDiscountPercentage}%</DataWrapper> (
        {getAgeSentence(DataWrapper)(ages, true)}){' '}
      </>
    );
  }
  return (
    <>
      , <DataWrapper>excludes</DataWrapper> any Youth Discount{' '}
    </>
  );
};

const getIncomeSentence =
  // @ts-expect-error - Automatic, Please fix when editing this file


    (DataWrapper) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    // eslint-disable-next-line react/prop-types
    ({ incomeTier, incomeType }) =>
      (
        <>
          <DataWrapper>{incomeType}</DataWrapper> income of <DataWrapper>{incomeTier}</DataWrapper> per year
        </>
      );

// @ts-expect-error - Automatic, Please fix when editing this file
const getRebateSentence = (DataWrapper) => (customer) => {
  const { ageBracket, rebatePercentage, rebate } = customer;
  return rebate ? (
    <>
      Price reduced by an Australian Government Rebate of <DataWrapper>{rebatePercentage}%</DataWrapper> (
      {getAgeSentence(DataWrapper)({ [constants.RELATIONSHIP.CLIENT]: ageBracket }, false)},{' '}
      {getIncomeSentence(customer.highlightIncome ? DataWrapper : Fragment)(customer)}).{' '}
    </>
  ) : (
    'Australian Government Rebate is not applied. '
  );
};

const getPriceSentence =
  // @ts-expect-error - Automatic, Please fix when editing this file


    (DataWrapper) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    // eslint-disable-next-line react/prop-types
    ({ scale, state, frequency }) =>
      (
        <>
          Price is for <DataWrapper>{scale}</DataWrapper> cover in <DataWrapper>{state}</DataWrapper> paying{' '}
          <DataWrapper>{frequency}</DataWrapper> by direct debit.{' '}
        </>
      );

const getLHCSentence =
  // @ts-expect-error - Automatic, Please fix when editing this file


    (DataWrapper) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    // eslint-disable-next-line react/prop-types
    ({ lhc }) =>
      lhc > 0 ? (
        <>
          {' '}
          and <DataWrapper>includes</DataWrapper> Lifetime Health Cover loading of <DataWrapper>{lhc}%</DataWrapper>.
        </>
      ) : (
        <>
          {' '}
          and <DataWrapper>excludes</DataWrapper> Lifetime Health Cover loading.{' '}
        </>
      );

// @ts-expect-error - Automatic, Please fix when editing this file
const getExcessPart = (DataWrapper) => (customer, product) => {
  const excessAmount = get('charges.excess.amount', product);
  if (isNil(excessAmount)) {
    return null;
  }
  return <DataWrapper>an excess of ${excessAmount}</DataWrapper>;
};

// @ts-expect-error - Automatic, Please fix when editing this file
const getProductSentence = (DataWrapper) => (customer, product) => {
  if (!product || get('type', product) === constants.PRODUCT_TYPE.EXTRAS) {
    return null;
  }

  return (
    <>
      {' '}
      {upperFirst(constants.PRODUCT_TYPE.HOSPITAL)} price includes{' '}
      {getExcessPart(customer.highlightExcess ? DataWrapper : Fragment)(customer, product)}
      {getYouthDiscountSentence(DataWrapper)(customer)}
      {getLHCSentence(DataWrapper)(customer)}
    </>
  );
};

// @ts-expect-error - Automatic, Please fix when editing this file
const getYouthDiscountPercentageFromPrices = (prices, productId, customer) => {
  const price = PriceService.getPrice(prices, productId, customer);
  return get('youthDiscount', price);
};

type PriceDisclaimerProps = {
  bold?: boolean;
  calculatedYouthDiscountPercentage: YouthDiscountPercentageType;
  cartHospitalOrPackageProduct?: {};
  center?: boolean;
  className?: string;
  constrainWidth?: boolean;
  customer: {
    rebate: boolean;
    state: string;
  };
  customerReadable: unknown;
  elementType?: 'button' | 'clickable';
  forProducts?: {}[];
  hideForDesktop?: boolean;
  highlightExcess?: boolean;
  highlightIncome?: boolean;
  indicator?: string;
  openChangeDetailsDrawer: () => void;
  prices?: {};
  rebatePercentage: string | number;
  showRefineLink?: boolean;
  showRefineLinkInline?: boolean;
};

const PriceDisclaimer = ({
  bold,
  calculatedYouthDiscountPercentage,
  cartHospitalOrPackageProduct,
  center,
  className,
  constrainWidth,
  customer,
  customerReadable,
  elementType,
  forProducts,
  hideForDesktop,
  highlightExcess,
  highlightIncome,
  indicator,
  openChangeDetailsDrawer,
  prices,
  rebatePercentage,
  showRefineLink,
  showRefineLinkInline,
}: PriceDisclaimerProps) => {
  const DataWrapper = bold ? Strong : Fragment;
  const radix = 10;

  // We check if the product excess matches customer excess, because we display greyed out products of other excess types on the comparer.
  const hospitalProductWithExcess = find((product) => {
    const customerExcess = parseInt(radix, get('hospitalExcess')(customer));
    const hasExcessMatchingCustomer = customerExcess === get('charges.excess.amount')(product);
    return hasHospitalComponent(product) && hasExcessMatchingCustomer;
  })(forProducts);

  const product = hospitalProductWithExcess || head(forProducts) || cartHospitalOrPackageProduct;
  // @ts-expect-error - Automatic, Please fix when editing this file
  const whicsYouthDiscountPercentage = product && getYouthDiscountPercentageFromPrices(prices, product.id, customer);

  const customerData = defaultsAll([
    {
      calculatedYouthDiscountPercentage,
      highlightExcess,
      highlightIncome,
      rebatePercentage,
      whicsYouthDiscountPercentage,
    },
    customerReadable,
    customer,
  ]);

  let refinePriceElement = null;
  if (showRefineLink) {
    refinePriceElement =
      elementType === 'button' ? (
        <div>
          <StyledButton center={center} onClick={openChangeDetailsDrawer} variant={['border', 'wide', 'white']}>
            Refine price
          </StyledButton>
        </div>
      ) : (
        // @ts-expect-error - Automatic, Please fix when editing this file
        <StyledDiv center={center}>
          <Clickable onClick={openChangeDetailsDrawer}>
            <Strong variant="small14">Refine these details</Strong>
          </Clickable>
        </StyledDiv>
      );
  }

  const Paragraph = StyledP;

  return (
    // @ts-expect-error - Automatic, Please fix when editing this file
    <DisclaimerWrapper hideForDesktop={hideForDesktop} data-nosnippet>
      <Paragraph $constrainWidth={constrainWidth} className={className}>
        {indicator}
        {/* @ts-expect-error - Automatic, Please fix when editing this file */}
        {getPriceSentence(DataWrapper)(customerData)}
        {getRebateSentence(DataWrapper)(customerData)}
        {getProductSentence(DataWrapper)(customerData, product)}
        {showRefineLinkInline && (
          <Clickable onClick={openChangeDetailsDrawer}>
            <Strong>Refine price</Strong>
          </Clickable>
        )}
      </Paragraph>
      {refinePriceElement}
    </DisclaimerWrapper>
  );
};

PriceDisclaimer.defaultProps = {
  bold: false,
  cartHospitalOrPackageProduct: null,
  center: false,
  className: null,
  constrainWidth: false,
  elementType: 'clickable',
  forProducts: [],
  hideForDesktop: false,
  highlightExcess: false,
  highlightIncome: false,
  indicator: '*',
  prices: null,
  showRefineLink: false,
  showRefineLinkInline: false,
};

const mapStateToProps = (state: RootState) => ({
  calculatedYouthDiscountPercentage: selectCalculatedYouthDiscountPercentage(state),
  cartHospitalOrPackageProduct: selectCartHospitalOrPackageProduct(state),
  customer: selectCustomer(state),
  customerReadable: selectCustomerHumanReadable(state),
  // @ts-expect-error - Automatic, Please fix when editing this file
  highlightExcess: selectIsExcessToggleApplicable(state),
  prices: selectPrices(state),
  rebatePercentage: selectRebatePercentage(state),
});

// @ts-expect-error - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => {
  const drawerActions = bindActionCreators(drawerActionCreators, dispatch);

  return {
    openChangeDetailsDrawer: () => drawerActions.openDrawer(DRAWER_IDS.CHANGE_DETAILS),
  };
};

// @ts-expect-error - Automatic, Please fix when editing this file
export default connect(mapStateToProps, mapDispatchToProps)(PriceDisclaimer);

export {
  getAgeSentence,
  getIncomeSentence,
  getLHCSentence,
  getProductSentence,
  getRebateSentence,
  getYouthDiscountSentence,
  mapStateToProps,
  PriceDisclaimer as PriceBreakdownForTest,
};
