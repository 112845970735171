import { Button, P } from '@ahmdigital/ui';
import styled, { css } from 'styled-components';

const textAlignStyles = css<{ center: boolean }>`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const StyledP = styled(P)`
  font-size: 0.75rem;
  margin: 0.5rem auto;
  max-width: ${({ $constrainWidth, theme }) => ($constrainWidth ? theme.media.sm : '100%')};
  text-size-adjust: 100%;
  width: 100%;
  ${textAlignStyles}
`;

export const StyledButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1rem auto 0;
  padding: calc(1rem - 2px);

  ${({ theme }) => css`
    ${theme.mediaQueries.below(theme.media.md)} {
      margin: 1rem 0 0;
    }
  `}
`;

export const StyledDiv = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.media.md};
  ${textAlignStyles}
`;

export const DisclaimerWrapper = styled.div`
  ${({ theme }) => css<{ hideForDesktop: boolean }>`
    ${theme.mediaQueries.above(theme.media.md)} {
      ${({ hideForDesktop }) => (hideForDesktop ? 'display: none;' : '')}
    }
  `}
`;
