import { createSelector } from 'reselect';
import { get, getOr } from 'lodash/fp';

import selectCart from '../select-cart';
import selectProducts from '../select-products';

const selectCartHospitalOrPackageProduct = createSelector([selectCart, selectProducts], (cart, products) => {
  const hospitalId = get('hospital.id', cart);
  const packageId = get('package.id', cart);
  return getOr(null, packageId || hospitalId, products);
});

export default selectCartHospitalOrPackageProduct;
